<template>
    <div class="container">
        <div class="topTitle">
            <span>订单编号：{{pageInfo.items.ordersn}}</span>
            <span>申请时间：{{pageInfo.items.ctime}}</span>
            <span v-if="pageInfo.items.ty===1">退款申请</span>
            <span v-if="pageInfo.items.ty===2">退货申请</span>
        </div>
        <div class="aboutOrder">
            <el-row>
                <el-col :span="8" v-if="pageInfo.items.ty ===2">
                    <div class="grid-content part1" v-if="pageInfo.items.state ===1">
                        <p>等待处理退款申请</p>
                        <p>
                            请在
                            <span class="red">6天12小时34分钟56秒</span>内处理完成，超时自动同意
                        </p>
                        <div class="grid-content">
                            <el-button type="primary" @click="state(2)">同意退款</el-button>
                            <el-button type="danger" @click="state(3)">拒绝退款</el-button>
                        </div>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.state ===2 && pageInfo.items.refund_state===3 &&pageInfo.items.is_complete!==2">
                        <p>等待退款到账</p>
                        <p>退款未到账，请等待退款原路退还用户账户</p>
                    </div>
                    <div class=" grid-content part1" v-if="pageInfo.items.state === 3">
                        <p><img src="../../assets/img/jujue.png" style="width:80px"></p>
                        <p>平台拒绝退款</p>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.state ===4">
                        <p>用户取消申请</p>
                        <p>用户已取消售后申请</p>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.is_complete===2">
                        <p><img src="../../assets/img/wancheng.png" style="width:80px"></p>
                        <p>商户完成退款</p>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.state ===2 && pageInfo.items.refund_state!==3 &&pageInfo.items.is_complete!==2">
                        <p>等待买家退货给平台</p>
                        <p>
                            等待买家
                            <span class="red">6天12小时34分钟56秒</span>内退货，超时取消退款
                        </p>
                        <div class="grid-content">
                            <el-button type="primary" @click="deliver()">确认到货，退款</el-button>
                        </div>
                    </div>

                </el-col>
                <el-col :span="8" v-if="pageInfo.items.ty ===1">
                    <div class="grid-content part1" v-if="pageInfo.items.state ===1">
                        <p>等待处理退款申请</p>
                        <p>
                            请在
                            <span class="red">6天12小时34分钟56秒</span>内处理完成，超时自动同意
                        </p>
                        <div class="grid-content">
                            <el-button type="primary" @click="state(2)">同意退款</el-button>
                            <el-button type="danger" @click="state(3)">拒绝退款</el-button>
                        </div>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.state ===2">
                        <p>等待退款到账</p>
                        <p>退款未到账，请等待退款原路退还用户账户</p>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.state === 3">
                        <p><img src="../../assets/img/jujue.png" style="width:80px"></p>
                        <p>平台拒绝退款</p>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.state ===4">
                        <p>用户取消申请</p>
                        <p>用户已取消售后申请</p>
                    </div>
                    <div class="grid-content part1" v-if="pageInfo.items.is_complete===2">
                        <p><img src="../../assets/img/wancheng.png" style="width:80px"></p>
                        <p>商户完成退款</p>
                    </div>
                </el-col>
                <el-col :span="16">
                    <div class="grid-content part2">
                        <el-steps :active="active" finish-status="success" align-center space="80%" :process-status="status" v-if="pageInfo.items.state ===3">
                            <el-step title="买家下单" description></el-step>
                            <el-step title="平台拒绝" description></el-step>
                            <el-step title="等待退货" description v-show="pageInfo.items.ty ===2"></el-step>
                            <el-step title="退款完成" description></el-step>
                        </el-steps>
                        <el-steps :active="active" finish-status="success" align-center space="80%" :process-status="status" v-else-if="pageInfo.items.state ===4">
                            <el-step title="买家下单" description></el-step>
                            <el-step title="用户取消申请" description></el-step>
                            <el-step title="等待退货" description v-show="pageInfo.items.ty ===2"></el-step>
                            <el-step title="退款完成" description></el-step>
                        </el-steps>
                        <el-steps :active="active" finish-status="success" align-center space="80%" :process-status="status" v-else>
                            <el-step title="买家下单" description></el-step>
                            <el-step title="平台审核" description></el-step>
                            <el-step title="等待退货" description v-if="pageInfo.items.ty ===2"></el-step>
                            <el-step title="退款完成" description></el-step>
                        </el-steps>
                    </div>
                </el-col>
            </el-row>
        </div>

        <div class="logis" v-if="logs.length>0">
            <el-tabs type="border-card" class="logis">
                <el-tab-pane label="物流1">
                    <div class="logisInfo">
                        <div class="infoLeft">
                            <div>
                                <span>快递公司：{{logs.express}}</span>
                                <span class="infoLeft_item">
                                    最新物流状态：
                                    <span class="red" v-if="logs.data">{{logs.data[0].context}}</span>
                                </span>
                            </div>
                            <div>
                                <span>快递单号：{{logs.expresssn}}</span>
                                <span class="infoLeft_item blue" @click="goDetail(key)">物流详情</span>
                            </div>
                            <div>发货时间：{{logs.delivery_time}}</div>
                        </div>
                        <div class="goodsBox">
                            <div class="goods">
                                <img :src="logs.thumb" />
                                <div class="ccontent">
                                    <p>{{logs.title}}</p>
                                    <p class="gray">{{logs.attr}}</p>
                                    <p class="num">
                                        <span>￥{{logs.price}}</span>
                                        <span>x{{logs.number}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>

        <div class="buyer" :gutter="30">
            <el-row>
                <el-col :span="6">
                    <div class="grid-content">
                        <div class="i-title">
                            <p>退款信息</p>
                        </div>
                        <div class="item">
                            <p>售后类型：</p>
                            <p v-if="pageInfo.items.ty===1">退款</p>
                            <p v-if="pageInfo.items.ty===2">退货</p>
                            <p v-else></p>
                        </div>
                        <div class="item">
                            <p>退款原因：</p>
                            <p>
                                {{pageInfo.items.reason}}
                            </p>
                        </div>
                        <div class="item">
                            <p>退款金额：</p>
                            <p>
                                <span class="red">{{pageInfo.items.price}}</span>
                            </p>
                        </div>
                        <div class="item">
                            <p>申请时间：</p>
                            <p>{{pageInfo.items.ctime}}</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content" style="border-left: 1px dashed rgb(199, 198, 198);border-right: 1px dashed rgb(199, 198, 198);">
                        <div class="i-title">
                            <p>支付信息</p>
                        </div>
                        <div class="item">
                            <p>订单编号：</p>
                            <p class="blue">{{pageInfo.order.ordersn}}</p>
                        </div>

                        <div class="item">
                            <p>付款时间：</p>
                            <p>{{pageInfo.order.pay_time}}</p>
                        </div>
                        <div class="item">
                            <p>活动优惠：</p>
                            <p>{{pageInfo.order.discount_price}}</p>
                        </div>
                        <div class="item">
                            <p>运费：</p>
                            <p>{{pageInfo.order.express_price}}</p>
                        </div>
                        <div class="item">
                            <p>付款金额：</p>
                            <p class="red">￥{{pageInfo.order.price}}</p>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="grid-content">
                        <div class="i-title">
                            <p>买家信息</p>
                        </div>
                        <div class="item">
                            <p>买家昵称：</p>
                            <p>{{pageInfo.addr.username}}</p>
                        </div>
                        <div class="item">
                            <p>手机号：</p>
                            <p>{{pageInfo.addr.phone}}</p>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="productsn" label="商品款号" align="center"></el-table-column>
            <el-table-column label="商品" align="center" width="300">
                <template slot-scope="scope">
                    <div class="goods">
                        <img :src="scope.row.thumb" />
                        <div class="ccontent">
                            <p>{{scope.row.title}}</p>
                            <p>{{scope.row.attr}}</p>
                        </div>
                        <!-- <div class="adjustment" @click="adjustment()">调整</div> -->
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="number" label="数量" width="100" align="center"></el-table-column>
            <el-table-column prop="goods_price" label="商品金额" width="150" align="center"></el-table-column>
            <el-table-column prop="price" label="实付金额" width="150" align="center"></el-table-column>
            <el-table-column prop="discount_price" label="优惠金额" width="150" align="center"></el-table-column>
            <el-table-column prop="commission_price" label="一级分佣" width="100" align="center"></el-table-column>
            <el-table-column prop="second_commission_price" label="二级分佣" width="100" align="center"></el-table-column>
            <el-table-column prop="is_refund" label="是否包含退货" width="150" align="center">
                <template slot-scope="scope" prop="is_refund">
                    <el-tag type="info" v-if="scope.row.is_refund === 0">不包含</el-tag>
                    <el-tag type="primary" v-else>包含</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="is_confirm" label="是否签收 " width="150" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.is_confirm === 0">未签收</el-tag>
                    <el-tag type="primary" v-if="scope.row.is_confirm === 2">已签收</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="is_settlement" label="佣金是否结算" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.is_settlement === 0">未结算</el-tag>
                    <el-tag type="primary" v-if="scope.row.is_settlement === 2">已结算</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="发货状态" prop="state" width="150" align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.state === 0">未发货</el-tag>
                    <el-tag type="primary" v-if="scope.row.state === 2">已发货</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { request } from '@/common/request';
import { deal } from '@/common/main';
import bus from '@/common/bus';

export default {
    name: 'RefundDetail',

    data() {
        return {
            id: 0,
            status: 'wait',
            context: '',
            active: 0,
            loading: false,
            pageInfo: {
                items: {},
                order: {},
                goods: {},
                addr: {}
            },
            tableData: [
            ],
            confirmVisible: false,
            confirmContent: '提示',
            refunt_state: 2,
            logs: [],
        };
    },
    mounted() {
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id != this.id && to.path == "/order-RefundDetail") {
                    this.id = to.query.id;
                    this.loadData();
                }
                //深度监听，同时也可监听到param参数变化
            },
            deep: true,
            immediate: true
        }

    },
    methods: {
        loadData() {
            this.loading = true;
            const _this = this
            request.get('/order/rights/detail', { id: this.id }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data
                    this.pageInfo.order.pay_time = deal.timestamp(this.pageInfo.order.pay_time, 'unix');
                    this.tableData = [ret.data.goods]
                    if (ret.data.items.ty === 1) {
                        if (ret.data.items.is_complete == 2) {
                            this.status = "wait"
                            this.active = 4
                        } if (ret.data.items.is_complete !== 2 && ret.data.items.state === 2) {
                            this.active = 2
                            this.status = "wait"
                        } if (ret.data.items.state === 1) {
                            this.active = 1
                            this.status = "wait"
                        } if (ret.data.items.state === 3) {
                            this.active = 1
                            this.status = "error"
                        } if (ret.data.items.state === 4) {
                            this.active = 1
                            this.status = "error"
                        }
                    } else {
                        if (ret.data.items.is_complete === 2) {
                            this.status = "wait"
                            this.active = 4
                        } if (ret.data.items.is_complete !== 2 && ret.data.items.state === 2) {
                            this.active = 2
                            this.status = "wait"
                        } if (ret.data.items.state === 1) {
                            this.active = 1
                            this.status = "wait"
                        } if (ret.data.items.state === 3) {
                            this.active = 1
                            this.status = "error"
                        } if (ret.data.items.state === 4) {
                            this.active = 1
                            this.status = "error"
                        }
                    }
                    if (ret.data.items.expresscom && ret.data.items.expresssn) {
                        this.loadLogInfo(ret.data.items.expresscom, ret.data.items.expresssn)
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },

        //获取物流信息
        loadLogInfo(expresscom, expresssn) {
            request.get('/order/logistics', { expresscom: expresscom, expresssn: expresssn }).then(ret => {
                if (ret.code == 1) {
                    this.$set(this, 'logs', ret.data.list)
                    this.logs = ret.data.list
                    if (this.logs.data) {
                        this.logs.data.map((item, index) =>
                            item.color = '#20a0ff'
                        )
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            })
        },
        //取消订单
        closeOrder() {

        },
        state(state) {
            if (state === 3) {
                this.refunt_state = 3;
                this.confirmContent = '确认拒绝退款'
            } else {
                this.refunt_state = 2;
                this.confirmContent = '确认同意退款'
            }
            this.confirmVisible = true
        },
        stateData() {
            request.post('/order/rights/edit', { id: this.id, state: this.refunt_state }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.$message.success((this.refunt_state == 3 ? '拒绝' : '通过') +
                        '成功');
                    bus.$emit('close_current_tags');
                    this.$router.push({ path: '/order-SalesOrders' })
                } else {
                    this.$message.error(ret.msg);
                }
                this.confirmVisible = false
            });
        },

        // 物流详情
        goDetail(key) {
            this.$router.push({ path: '/order-logistics', query: { 'id': this.id, 'key': key + '' } })
        },

    },
};
</script>

<style lang="scss" scoped>
.red {
    color: red;
}
.topTitle {
    color: #666;
    font-size: 14px;

    span:nth-child(2) {
        padding: 0 15px;
    }
}

.aboutOrder {
    border: 1px solid rgb(199, 198, 198);
    height: 157px;
    margin-top: 15px;

    .part1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: 1px solid rgb(199, 198, 198);
        // padding: 30px 0;
        height: 157px;
        p:nth-child(1) {
            font-size: 25px;
            text-align: center;
        }

        p:nth-child(2) {
            font-size: 13px;
            text-align: center;
            color: #666;
            padding-top: 5px;
        }

        div {
            text-align: center;
            margin-top: 10px;
        }
    }

    .part2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 157px;
        .first {
            width: 75px;
            text-align: center;

            p:nth-child(1) {
                padding-bottom: 10px;
            }

            .date {
                padding-top: 10px;
                color: #666;
                font-size: 13px;
                height: 40px;
            }
        }

        .success {
            color: #409eff;
            font-size: 30px;
        }

        .line {
            width: 100px;
            height: 4px;
            background: #409eff;
            margin-right: 10px;
        }

        .time {
            color: #999;
            font-size: 30px;
        }

        .lines {
            width: 100px;
            height: 4px;
            background: #999;
            margin: 0 10px;
        }
    }
}

.buyer {
    border: 1px solid rgb(199, 198, 198);
    margin-top: 10px;
    padding: 30px 0;

    .i-title {
        text-align: center;
        font-size: 15px;
        display: flex;

        p {
            flex: 1;
            text-align: center;
        }

        p:nth-child(2) {
            color: #666;
            font-size: 13px;
        }
    }

    .item {
        display: flex;
        font-size: 13px;
        color: #666;
        margin-top: 10px;

        p:nth-child(1) {
            flex: 1;
            text-align: right;
        }

        p:nth-child(2) {
            flex: 2;
        }
    }

    .content {
        border-left: 1px dashed rgb(199, 198, 198);
    }
}

.goods {
    display: flex;

    img {
        width: 50px;
        height: 50px;
    }

    .ccontent {
        padding: 0 10px;
        color: #666;
    }

    .adjustment {
        color: #ff4500;
    }
}
.el-steps {
    width: 100%;
}
.logis {
    margin-top: 20px;
}
.blue {
    color: blue;
}
.tui {
    cursor: pointer;
}

.logisInfo {
    width: 100%;
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 20px;

    .infoLeft {
        div {
            color: #999;
            font-size: 12px;
            line-height: 25px;
            .infoLeft_item {
                margin-left: 100px;
            }
            .blue {
                cursor: pointer;
                color: blue;
            }
        }
    }
    .goodsBox {
        display: flex;
        .goods {
            border: 1px solid #eeeeee;
            padding: 10px;
            margin: 0 20px;
        }
        .ccontent {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            p {
                font-size: 12px;
                color: #000;
            }
            .gray {
                color: gray;
            }
        }
    }
    .num {
        display: flex;
        justify-content: space-between;
    }
}
</style>